/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_mobile_analytics_app_id": "199ebc15739a46a0883c3c0d61842b14",
    "aws_mobile_analytics_app_region": "eu-west-2",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "199ebc15739a46a0883c3c0d61842b14",
            "region": "eu-west-2"
        }
    },
    "aws_appsync_graphqlEndpoint": "https://fjxkxyrbu5clvbmfhux756rn5u.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "checkout",
            "endpoint": "https://v17r57oet4.execute-api.eu-west-2.amazonaws.com/prod",
            "region": "eu-west-2"
        },
        {
            "name": "enrolmentmanager",
            "endpoint": "https://kiitcn8k4c.execute-api.eu-west-2.amazonaws.com/prod",
            "region": "eu-west-2"
        },
        {
            "name": "incomingwebhooks",
            "endpoint": "https://bvo9svtzrl.execute-api.eu-west-2.amazonaws.com/prod",
            "region": "eu-west-2"
        }
    ],
    "aws_cognito_identity_pool_id": "eu-west-2:9771ac73-b774-415d-a314-ba15b110642d",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_whd9XbnQA",
    "aws_user_pools_web_client_id": "5en9o7q4sn8dg2jsuabhou47n0",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "amplifyapp15babf61e9a3434db5e4ba3de07ba21d120533-prod",
    "aws_user_files_s3_bucket_region": "eu-west-2"
};


export default awsmobile;
